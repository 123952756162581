// core imports
import { DeleteButton, Toolbar as RaToolbar, SaveButton } from 'react-admin';

/**
 * Toolbar Component
 * @returns <Toolbar> ReactNode
 */
export const Toolbar = ({
  isDisabledDelete = false,
  isAlwaysEnabled = true,
  hasDelete = true,
  deleteLabel = 'ra.action.delete',
}: {
  isDisabledDelete?: boolean;
  isAlwaysEnabled?: boolean;
  hasDelete?: boolean;
  deleteLabel?: string;
}) => (
  <RaToolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
    <SaveButton alwaysEnable={isAlwaysEnabled} />
    {hasDelete && (
      <DeleteButton disabled={isDisabledDelete} label={deleteLabel} />
    )}
  </RaToolbar>
);

Toolbar.defaultProps = {
  isDisabledDelete: false,
  isAlwaysEnabled: true,
  hasDelete: true,
  deleteLabel: 'ra.action.delete',
};
